import { SIZE_DEFAULT } from "../../constants/product"
import { SERVE_HOT_ID } from "../../util/product/wishes"
import { getStatsProduct } from "../../util/product/stats"
import { roundedNum } from "../../util/number"

const getOptionByBasics = (activeBase, activeSize) => {
  const result = {}
  Object.entries(activeBase).forEach(([option_id, { option_value_id, price }]) => {
    result[option_id] = [{
      option_value_id,
      option_id,
      price,
      quantity: activeSize.coefficient
    }]
  })

  return result
}
const getSingleOptionByList = (active = null, options) => {
  if (!active || !options) {
    return {}
  }

  const option_id = options.option_id
  return {
    [option_id]: [{
      option_id,
      option_value_id: active.option_value_id,
      price: active.price,
      quantity: 1,
    }]
  }
}

const getMultipleOptionsByList = (activeList = [], options) => {
  if (activeList.length === 0 || !options) {
    return {}
  }

  const option_id = options.option_id
  const result = activeList.map((item) => ({
    option_id,
    option_value_id: item.option_value_id,
    price: item.price,
    quantity: 1,
  }))


  return {
    [option_id]: result
  }
}

export const getAllOptions = ({
  product,
  options,
  activeBase,
  activeOptions,
}) => {
  const {
    cookTypes: activeCookTypes = null,
    wishes: activeWish = null,
    serving: activeServing = null,
    taste: activeTaste = [],
    size: activeSize,
  } = activeOptions
  const result = {
    ...options,
    ...getOptionByBasics(activeBase, activeSize),
    ...getSingleOptionByList(activeCookTypes, product.cooktypes),
    ...getMultipleOptionsByList([...activeTaste, activeServing, activeWish].filter((v) => v), product.wishes)
  }

  return result
}

export const getDefaultOptions = (product) => {
  const defaultOptions = {}
  product.ingredients.forEach(({ option_id, items }) => {
    const list = []
    items.forEach(({ default_count, option_value_id, price }) => {
      if (default_count > 0) {
        list.push({
          option_id,
          option_value_id,
          price,
          quantity: default_count,
        })
      }
    })

    if (list.length) {
      defaultOptions[option_id] = list
    }
  })

  return defaultOptions
}

export const getDefaultActiveBase = ({ basics = [] }) => {
  if (!basics.length) {
    return {}
  }

  const defaultActiveBase = {}
  basics.forEach(({ option_id, items }, index) => {
    const defaultBasic = items.find(({ default_count }) => default_count > 0)
    if (defaultBasic) {
      defaultActiveBase[option_id] = defaultBasic
    }
  })

  return defaultActiveBase
}

const _addWeight = (weight) => weight ? `, ${roundedNum(weight, true)} г` : ''


export const getActiveBasesStringList = (activeBase, activeSize) => (
  Object.entries(activeBase).map(([_, item]) => {
    const quantity = 1 * activeSize.coefficient
    return {
      label: `${item.name} – x${quantity}`,
      // label: `${item.name} – x${quantity}${_addWeight(item.weight * quantity)}`,
      stats: getStatsProduct(item.nutrition, quantity, ' | '),
      weight: item.weight,
      nutrition: item.nutrition,
      quantity,
      name: item.name,
      id: item.option_value_id,
    }
  })
)

export const getActiveModifiersStringList = (options, allIngredients) => {
  const result = []
  Object.entries(options).forEach(([key, items]) => {
    const initialItems = allIngredients.find(({ option_id }) => `${option_id}` === `${key}`)?.items
    if (!initialItems) {
      return
    }

    items.forEach(({ option_value_id, quantity }) => {
      const initialItem = initialItems.find(({ option_value_id: idx }) => idx === option_value_id) || null
      if (!initialItem?.name) {
        return
      }
      result.push({
        label: `${initialItem.name} – x${quantity}`,
        // label: `${initialItem.name} – x${quantity}${_addWeight(initialItem.weight * quantity)}`,
        stats: getStatsProduct(initialItem.nutrition, quantity, ' | '),
        weight: initialItem.weight,
        nutrition: initialItem.nutrition,
        quantity,
        name: initialItem.name,
        id: initialItem.option_value_id,
      })
    })
  })

  return result
}

export const getDefaultStateOptions = (cookTypesList, lists) => {
  const SERVE_HOT = lists.serving.items.find(({ code }) => code === SERVE_HOT_ID) || null

  const cookTypes = cookTypesList?.items ? cookTypesList.items.find(({ default_count }) => default_count > 0) || null : null
  const wishes = lists.wishes.items.find(({ default_count }) => default_count > 0) || null
  const serving = lists.serving.items.find(({ default_count }) => default_count > 0) || null
  const taste = lists.taste.items.filter(({ default_count }) => default_count > 0) || []
  return {
    size: SIZE_DEFAULT,
    cookTypes,
    wishes,
    serving: cookTypes ? SERVE_HOT : serving,
    taste,
  }
}
