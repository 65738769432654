import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import {defaultCategories, getMenu} from '../../query/categories'
import { getBackgroundColor } from '../../util/catalog/color'
import emptyImg from '../../img/empty/catalog-modal.png'
import './styles.scss'

const getImage = (image, isDefault = false) => (!isDefault && image) ? image : emptyImg

const defaultGroups = [{ id: 'default', title: '', isDefault: true, list: defaultCategories }]

const getTransformGroups = (categories = []) => {
  const aliasChef = ['Zavtrak-ot-Shefa', 'Obed-i-uzhin-ot-Shefa', 'Zavtrak chef', 'Obed-i-uzhin-', 'Obed-i-uzhin-chef'].map((val) => val.toLowerCase())
  const listChef = []
  const listOther = []

  categories.forEach((item) => {
    if (aliasChef.some((val) => val === item.alias.toLowerCase())) {
      listChef.push(item)
      return
    }

    listOther.push(item)
  })
  return [
    { id: 'chef', title: 'Тарелки от Шефа', list: listChef },
    { id: 'other', title: 'Тарелки Конструктор', list: listOther }
  ]
}

const FilterProduct = ({ active = null, setLoadingUI, onClose }) => {
  const history = useHistory()
  const [groups, setGroups] = useState(defaultGroups)

  useEffect(()=>{
    getMenu((res) => {
      const filteredList = res.filter(({ type }) => type === 'menu')
      setGroups(getTransformGroups(filteredList))
    }, () => {}, { useCache: true });
  }, [])

  const isLoading = useMemo(() => groups?.[0].isDefault, [groups?.[0].isDefault])
  const onClick = useCallback((alias, type) => {
    if (active === alias) {
      onClose()
      return
    }
    setLoadingUI()
    const base = type === 'category' ?  '/Category/' : '/Subcategory/'
    history.replace(`${base}${alias}`)
  }, [history.replace, setLoadingUI, active, onClose])

  return (
    <div className={clsx('filter-product', isLoading && 'loadData')}>
      {groups.map(({ id, title = '', list = [] }) => (
        <div className="filter-product__section" key={id}>
          {title ? <p className="filter-product__section-title">{title}</p> : null}
          <div className="filter-product__section-list">
            {list.map(({ id, alias, name, image, type, default: isDefault = false }) => (
              <div className="filter-product__item" key={id}>
                <div
                  onClick={() => onClick(alias, type)}
                  className={clsx('filter-product__link', 'text', active === alias && 'active')}
                >
                  <div
                    className="filter-product__link-content"
                    style={{ backgroundImage: `url('${getImage(image, isDefault)}')`, backgroundColor: getBackgroundColor({ default: isDefault, alias, name }) }}>
                    <p className="filter-product__name">{name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default FilterProduct
