import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
// import VideoOnboarding from "../../components/onboarding";
import {guestPhoneSelector} from "../../store/selectors/user";
import {setUserInfo} from "../../store/actions/user";
import API from "../../api";
import { useReducer } from "../../util/store";
import useLayout from "../../util/layout";
import { INITIAL_SEC_RESEND_CODE } from "../../constants/code";
import TopPage from "../../components/top-page";
import Input from "../../components/input/index";
import { updateAddress, resetAddress } from "../../query/address";
import './login.scss'


const Login = () => {
    const refCode = useRef(null)
    const refForm = useRef(null)
    useLayout()
    const location = useLocation()
    const address = location?.state?.address
    const history = useHistory();
    const dispatch = useDispatch()
    const phone = useSelector(guestPhoneSelector)
    const [alert, setAlert] = useReducer('alert');
    const [isRepeat, toggleRepeat] = useState(false);
    const [resend, setResend] = useState(INITIAL_SEC_RESEND_CODE);
    const [code, setCode] = useState('');
    const isDisabled = useMemo(() => code.length < 3, [code])

    useEffect(() => {
        if (phone.length === 0) {
            history.replace('/')
        }
    }, [phone.length, history])

    useEffect(() => {
        let timer = null
        if (isRepeat) {
            return () => clearInterval(timer)
        }

        timer = setInterval(() => {
            if (resend === 0) return
            setResend((prev) => {
                const state = prev - 1
                if (state === 0) {
                    toggleRepeat(true)
                }
                return state
            })
        }, 1000)

        return () => clearInterval(timer)
    }, [isRepeat])

    const onChange = useCallback((e) => {
        setCode(e.target.value)
    }, [])

    const onClear = useCallback((e) => {
        setCode('')
    }, [])

    const onRepeatSendCode = useCallback((e) => {
        e.preventDefault();
        setResend(INITIAL_SEC_RESEND_CODE)
        toggleRepeat(false)
        API.post('/user/check', { telephone: phone }, () => {}, () => {}, { typeAlert: 'success' });
    }, [phone])

    const login = useCallback((e) => {
        e?.preventDefault();
        if (isDisabled) {
            return
        }
        API.post(
            '/user/confirm',
            {
                telephone: phone,
                code: code
            },
            function (response){
                if(response.telephone){
                    // После успешной авторизации, нужно проверить текущий адрес юзера и возможно обновить его новым адресом.
                    const successCallback = address ? () => updateAddress(address) : () => {}
                    // После авторизации очищаем локальный адрес, который мог остаться от предыдущего юзера.
                    resetAddress()
                    API.login(response.telephone, code, successCallback)
                    dispatch(setUserInfo({ ...response, code }))
                }
            },
            function (response){
                setAlert( { danger: 'Неправильный код' } );
            },
            { isGuest: true }
        );
    }, [isDisabled, phone, code, dispatch, address])

    useEffect(() => {
        const flag = 'OTPCredential' in window
        if (!flag) {
          return
        }

        const input = refCode.current
        const form = refForm.current
        if (!input) return;
        // Cancel the WebOTP API if the form is submitted manually.
        const hasAbortController = !!window?.AbortController
        const ac = hasAbortController ? new AbortController() : null;

        if (form) {
          form.addEventListener('submit', e => {
            // Cancel the WebOTP API.
            if (hasAbortController) {
                ac?.abort();
            }
          });
        }
        const body = {
            otp: { transport:['sms'] },
        }

        if (hasAbortController) {
            body.signal = ac?.signal
        }
        // Invoke the WebOTP API
        navigator.credentials.get(body).then(otp => {
            setCode(otp.code)
            // Automatically submit the form when an OTP is obtained.
            if (form) {
                form.dispatchEvent(
                    new Event("submit", { cancelable: true, bubbles: true })
                );
            }
        }).catch(err => {
        //   console.log(err);
        });

        return () => {
            if (hasAbortController) ac?.abort()
        }
    }, [])

    return (
        <div className="login-page layout">
            <div className="layout__top layout__top--gray">
                <TopPage
                    onClick={history.goBack}
                    title="Код из SMS"
                />
            </div>
            <div className="layout__body">
                <div className="grid-inner">
                    <form className="login-page__block" onSubmit={login} disabled={isDisabled} ref={refForm}>
                        <div className="login-page__field">
                            <Input
                                onChange={onChange}
                                name="sms"
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                autoComplete="one-time-code"
                                placeholder="Code"
                                label={`Код отправлен на номер ${phone}`}
                                value={code}
                                autoFocus
                                ref={refCode}
                            />
                        </div>
                        <div className="login-page__field">
                            <button
                                type="button"
                                className="btn btn-transparent"
                                disabled={!isRepeat}
                                onClick={onRepeatSendCode}
                            >
                                {isRepeat ? 'Отправить новый код' : `Получить новый код можно через 00:${`${resend}`.padStart(2, '0')}`}
                            </button>
                        </div>
                        <div className="login-page__field">
                            <Button type="submit" className="btn btn-primary" disabled={isDisabled}>Продолжить</Button>
                        </div>
                    </form>
                </div>
            </div>
            {/* <VideoOnboarding
                innerClassName="grid-inner"
                title="Как работает Тарелка?"
            /> */}
        </div>
    )
}

export default Login
