import React from "react";
import {render} from "react-dom";
import {Provider} from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from "./store";
import Message from "./components/message";
import App from "./App";
import './style.scss'


render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Message />
            <App/>
        </PersistGate>
    </Provider>,
    document.getElementById("app")
)
