import React, { useMemo } from 'react'
import clsx from 'clsx'
import Image from '../image'
import { getFormattedPrice } from '../../util/number'
import { getAllStatsOrderProduct, nonBreakingSpace } from '../../util/product/stats'
import { roundedNum } from '../../util/number'
import emptyImg from '../../img/empty/order.png'
import './styles.scss'

const OrderProduct = ({
  data: product,
  quantity = 1,
  type = 'order',
  children,
}) => {
  const renderName = useMemo(() => {
    if (type === 'order') {
      return `${product.name}${product.quantity ? ` - x${product.quantity}` : ''}`
    }

    return product.name
  }, [product.name, product.quantity, type])

  const renderInfo = useMemo(() => {
    const info = getAllStatsOrderProduct(product.options)
    const price = <span className="order-product__price">{getFormattedPrice(quantity * product.price)} ₽</span>
    const weight = info.weight ? <span className="order-product__weight">{info.weight}</span> : null
    const stats = info.stats ? <span className="order-product__stats"> | {info.stats}</span> : null

    return <>{price} {weight} {stats}</>

  }, [quantity, product])

  return (
    <div className={clsx('order-product', `_${type}`)}>
      <div className="order-product__inner">
        <div className="order-product__img">
          <Image src={product.image} alt="" emptyImg={emptyImg}/>
        </div>
        <div className="order-product__content">
          <p className="order-product__name">{renderName}</p>
          <p className="order-product__info">
            {renderInfo}
          </p>
          {product.options.length ? (
            <div className="order-product__options">
              {product.options.map((item, index) => (
                <div className="order-product__option-item" key={index}>
                  {item.name}{item.quantity ? ` - x${item.quantity}` : ''}{nonBreakingSpace}
                  {item.weight ? `, ${roundedNum(item.weight * item.quantity, true)}${nonBreakingSpace}г` : ''}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {children ? (
          <div className="order-product__controls">
            {children}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default OrderProduct
