import React from 'react'
import clsx from 'clsx'
import { useReducer } from '../../util/store'
import './styles.scss'

const Message = (props) => {
  let initState = {
    'danger': '',
    'info': '',
    'success': ''
  };
  const [alert, setAlert] = useReducer('alert', 'Message', initState);

  const clear = () => {
    setAlert(initState);
  }

  let message = alert.danger || alert.info || alert.success;
  let css = 'd-none';
  if(alert.info){
    css = 'message--info';
  }
  if(alert.danger){
    css = 'message--danger';
  }
  if(alert.success){
    css = 'message--success';
  }

  if(message) {
    setTimeout(() => {
      clear();
    }, 6000);
  }

  return (
    <div className={clsx('message', css)} onClick={clear}>
      <div className="message__icon" />
      <div className="message__text">{message}</div>
    </div>
  )
}

export default Message
