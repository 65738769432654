const DEFAULT_COLOR = '#F6F7F8'

const DATA = {
  'Завтрак': '#FFF3EB', // Завтрак
  'Обед и ужин': '#F2FFF3', // Обед/Ужин
  'от Шефа': '#EFF5FF', // от Шефа
  'Тарелки от Шефа': '#EFF5FF', // от Шефа
  'Обед и ужин - Шеф': '#F2FFF3', // Обед и ужин - Шеф
  'Обед и ужин от Шефа': '#F2FFF3', // Обед и ужин - Шеф
  'Завтрак -  Шеф': '#FFF3EB', // Завтрак - Шеф
  'Завтрак от Шефа': '#FFF3EB', // Завтрак - Шеф
  'Сюрприз наборы': '#FFEFF4', // Сюрприз наборы
  'Конструктор': '#F6F2FF', // Конструктор
  'Тарелки конструктор': '#F6F2FF', // Конструктор

  'Диета': '#EFF9FF', // Диета
  'Комбо': '#FDFFF2', // Комбо
  'XXXL': '#FFF9F0', // Комбо
}

const getColors = (data) => {
  const result = {}
  for (const [key, color] of Object.entries(data)) {
    const name = key.trim().toLowerCase()
    result[name] = color
  }

  return result
}

const colors = getColors(DATA)

export const getBackgroundColor = (data) => {
  if (data.default || !data.name) {
    return DEFAULT_COLOR
  }

  const name = data.name.trim().toLowerCase()
  return colors[name] || DEFAULT_COLOR
}
