import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import Image from '../image'
import { useReducer } from '../../util/store'
import { useScrollCategory } from '../../util/category/hook'
import Option from '../option'
import Input from '../input/index'
import './options.scss'
import './modifiers.scss'
import { MAX_COUNT_MODIFIERS_BY_SINGLE_GROUP } from '../../constants/modifiers'
import { MAX_COUNT } from '../../constants/comment'
import emptyOptionImg from '../../img/empty/filter.png'

const [alert, setAlert] = useReducer('alert')

const OptionItem = ({
  label = '',
  visibleImg = false,
  image,
  onChange = () => {},
  active = false,
}) => (
  <div className={clsx('option-item', active && 'active')} onClick={() => onChange(active)}>
    {visibleImg ? (
      <div className="option-item__image">
        <Image src={image} alt={label} emptyImg={emptyOptionImg} />
      </div>
    ) : null}
    <p className="option-item__label">{label}</p>
  </div>
)

const getAllQuantity = (options) => {
  let added = 0

  if (options) {
    options.forEach(item => {
      added += item.quantity
    })
  }

  return added
}

const getQuantity = (options, option_value_id) => {
  let qty = 0;
  if (options) {
    options.forEach(item => {
      if (item.option_value_id === option_value_id) {
        qty = item.quantity
      }
    })
  }

  return qty
}

const CommentBlock = ({ onChange }) => {
  const [comment, setComment] = useState('')
  const onChangeComment = useCallback((e) => {
    const value = e.target.value.length >= MAX_COUNT ? e.target.value.slice(0, MAX_COUNT) : e.target.value
    setComment(value)
    onChange(value)
  }, [onChange])

  return (
    <div className="options-block">
      <label className="options-block__label" htmlFor="product-comment">Пожелания к блюду</label>
      <div className="options-block__body">
        <Input
          value={comment}
          onChange={onChangeComment}
          placeholder="Добавьте пожелания"
          isTextarea
          mod="options-block__comment"
          id="product-comment"
          error={comment.length >= MAX_COUNT ? `Макс. кол-во символов: ${MAX_COUNT}` : null}
        />
      </div>
    </div>
  )
}

const CardOptionsBlock = ({
  data,
  title = '',
  onChange,
  activeOption,
  isMultipleChoice = false
}) => {
  if (!data || !data?.items?.length) {
    return null
  }

  return (
    <div className="options-block">
      <p className="options-block__label">{title}</p>
      <div className="options-block__body">
        <div className="options-block__list">
          {data.items.map((item) => (
            <OptionItem
              key={item.option_value_id}
              label={item.name}
              onChange={(isActive = false) => isMultipleChoice ? onChange(item) : onChange(!isActive ? item : null)}
              visibleImg
              image={item.image}
              active={isMultipleChoice ? (
                activeOption.some(({ option_value_id }) => option_value_id === item.option_value_id)
              ) : item.option_value_id === activeOption?.option_value_id}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export const CardOptions = ({
  cookTypes = null,
  wishes = null,
  serving = null,
  taste = null,
  sizeList = [],
  activeOptions = {},
  handlers = {},

  onChangeComment = () => {},
  hideSizes = false
}) => {

  return (
    <div className="options">
      <div className="grid-inner">
        {!hideSizes ? (
          <div className="options-block">
            <p className="options-block__label">Выберите размер</p>
            <div className="options-block__body">
              <div className="options-switch">
                {sizeList.map((item) => (
                  <div
                    key={item.id}
                    className={clsx('options-switch__item', activeOptions.size.id === item.id && 'active')}
                    onClick={() => handlers.onChangeSize(item)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        <CardOptionsBlock
          title="Способ приготовления"
          data={cookTypes}
          activeOption={activeOptions.cookTypes}
          onChange={handlers.onChangeCookTypes}
        />
        <CardOptionsBlock
          title="Пожелания к блюду"
          data={wishes}
          activeOption={activeOptions.wishes}
          onChange={handlers.onChangeWishes}
        />
        <CardOptionsBlock
          title="Подача"
          data={serving}
          activeOption={activeOptions.serving}
          onChange={handlers.onChangeServing}
        />
        <CardOptionsBlock
          title="Вкус"
          data={taste}
          activeOption={activeOptions.taste}
          onChange={handlers.onChangeTaste}
          isMultipleChoice
        />

        {/* TODO: временно скрыли поле с комментом к товару */}
        {/* <CommentBlock onChange={onChangeComment} /> */}
      </div>
    </div>
  )
}

const config = {
    isStickyTabs: true,
    addOffset: 70,
}

export const CardModifiers = ({
  data: product,
  options,
  setOptions
}) => {
  const showAlertMaxCountModifiers = useCallback(() => {
    setAlert({ info: `В одной группе доступен выбор только ${MAX_COUNT_MODIFIERS_BY_SINGLE_GROUP} модификаторов` })
  }, [])
  const [freeCount, setFreeCount] = useState(0);

  const changeOptions = (option_id) => (originOption) => {
    const option = { ...originOption }
    option.option_id = option_id;
    let _options = options[option_id] ? options[option_id] : [option];

    let push = true;
    _options.map((item, index) => {
      if(item.option_value_id == option.option_value_id){
        _options[index] = option;
        push = false;
      }
    });

    if(push) _options.push(option);

    let free = 0;
    product.ingredients.map(item => {
      free += getAllQuantity(options[item.option_id]);
    })

    setFreeCount(free);

    let opt = {};
    // Оставляем модификаторы с кол-вом больше 0
    opt[option_id] = _options.filter(({ quantity }) => quantity > 0)
    setOptions({...options, ...opt})
  }

  const {
    activeModifiers,
    onClick: onClickMod,
    refRootSections,
    refRootTabs,
    refScrollTabs,
  } = useScrollCategory(product.ingredients.length || 0, config)

  return (
    <div className="modifiers-root" >
      <div className="modifiers-tab" ref={refRootTabs}>
        <div className="modifiers-tab__scroll" ref={refScrollTabs}>
          {product.ingredients.map((item, index) => {
            let added = getAllQuantity(options[item.option_id]);
            return (
              <div
                onClick={() => onClickMod(item.option_id)}
                key={(item.option_id || index) + '_' + added}
                data-tab-id={item.option_id}
                className={`modifiers-item-link ${`${activeModifiers.id}` === item.option_id.toString() ? 'active' : ''}`}
              >
                {item.name} ({added}/{MAX_COUNT_MODIFIERS_BY_SINGLE_GROUP})
                {/* {item.name} ({added}/{item.free_count}) */}
              </div>
            );
          })}
        </div>
      </div>
      <div className="modifiers-scroll" ref={refRootSections}>
        {product.ingredients.map((option, index) => {
          let added = getAllQuantity(options[option.option_id]);
          return (
            <div
              key={option.option_id || index}
              className="modifiers-item"
              data-section-id={option.option_id}
              data-section-name={option.name}
            >
              <div className="modifiers-title">{option.name}</div>
              <div className="modifiers-flex">
                {option.items.map((item, index) => {
                  return (
                    <Option
                      index={index}
                      key={index}
                      data={item}
                      onChange={changeOptions(option.option_id)}
                      quantity={getQuantity(options[option.option_id], item.option_value_id)}
                      added={added}
                      free_count={option.free_count}
                      showAlertMaxCountModifiers={showAlertMaxCountModifiers}
                    />
                  )
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}
