import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import clsx from 'clsx'
import Header from "../../components/header";
import ProductList from "../../components/product-list";
import FilterProduct from "../../components/filter-product";
import { defaultCategory } from "../../query/categories";
import {defaultProducts, getProducts} from "../../query/products";
import CartBtn from "../../components/cart-btn";
import Modal from "../../components/modal";
import EmptyBlock from "../../components/empty-block";
import BtnBack from "../../components/btn-back";
import { HeadNested } from '../../components/head'
import {useReducer} from "../../util/store";
import useBodyMod from "../../util/body-mod";
import useLayout from "../../util/layout";
import Icon from "../../img/icons/arrow-down-small.svg";
import { useScrollCategory } from '../../util/category/hook.js'
import { CHANGE_LAYOUT } from '../../constants/events'
import { GROUP_CATALOG_LISTING } from '../../constants/cache'
import "./styles.scss"

const config = {
    isStickyTabs: false,
    addOffset: 200
}

const getProductsByPromise = (alias) => (
    new Promise((resolve, reject) => getProducts({ alias }, resolve, reject, { useCache: true, group: GROUP_CATALOG_LISTING }))
)

const Subcategory = () => {
    useLayout()
    useBodyMod('body-white')
    const history = useHistory();
    const [visibleFilters, toggleVisibleFilters] = useState(false)
    const [filter, setFilter] = useReducer('filter', 'Subcategory', []);

    const [data, setData] = useState({
        categoryName: defaultCategory.name,
        products: defaultProducts,
        subCategories: [],
        isLoading: true,
    });
    const {alias} = useParams();

    useEffect(() => {
        toggleVisibleFilters(false);

        const func = async () => {
            try {
                const promises = [
                    getProductsByPromise(alias),
                ]
                const results = await Promise.allSettled(promises)

                if (!(results[0].status === 'fulfilled')) {
                    throw new Error('Error')
                }
                const res = results[0].value

                setData({
                    ...res,
                    subCategories: res.subCategories.filter(({ id }) => res.products.some(({ categoryId }) => id === categoryId)),
                    isLoading: false,
                })

                const event = new CustomEvent(CHANGE_LAYOUT)
                window.dispatchEvent(event);
            } catch (error) {
                console.log(error)
            }
        }

        func()
    }, [alias, filter.length]);


    const onShowFilter = useCallback(() => toggleVisibleFilters(true), [])
    const onHideFilter = useCallback(() => toggleVisibleFilters(false), [])

    const isChanged = useMemo(() => data, [data])

    const {
        activeModifiers,
        onClick: onClickTab,
        refRootSections,
        refRootTabs,
        refScrollTabs,
    } = useScrollCategory(data.subCategories.filter(({ default: isDefault }) => !isDefault).length || 0, config, isChanged)

    const setLoadingUI = useCallback(() => {
      setData({
        categoryName: defaultCategory.name,
        products: defaultProducts,
        subCategories: [],
        isLoading: true,
      })
    }, [])

    const isEmpty = useMemo(() => data.products.length === 0, [data.products.length])

    return (
        <div className={clsx('layout', 'subcategory', isEmpty && '_catalog-empty', data.isLoading && 'loadData')}>
            <HeadNested  page="subcategory" name={data.categoryName} />
            <div className="layout__top">
                <Header count={data.products.length} isVisibleProductList />
                <div className="grid-inner">
                    <div className="subcategory__top">
                        <BtnBack
                            onClick={history.goBack}
                            // to={parentCategoryInfo?.alias ? `/Category/${parentCategoryInfo.alias}` : '/Catalog'}
                            mod="subcategory__back"
                        />
                        <div className="subcategory-filter" onClick={onShowFilter}>
                            <span className="subcategory-filter__name text">{data.categoryName}</span>
                            <span className="subcategory-filter__icon">
                                <Icon />
                            </span>
                        </div>
                    </div>
                </div>
                {data.subCategories.length ? (
                    <div className="grid-inner">
                        <div className="ingredients" ref={refRootTabs}>
                            <div className="ingredients-scroll" ref={refScrollTabs}>
                                {data.subCategories.map((subcategory) => (
                                    <div
                                        key={subcategory.id}
                                        className={clsx('ingredients-tag', 'text', activeModifiers.id === subcategory.alias && 'active')}
                                        onClick={() => onClickTab(subcategory.alias)}
                                        data-tab-id={subcategory.alias}
                                    >
                                        {subcategory.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>

            <div className="layout__body">
                <div className="grid-inner subcategory__scroll">
                    {!isEmpty ? (
                        <ProductList data={data.products} categories={data.subCategories} ref={refRootSections}/>
                    ) : (
                        <EmptyBlock type="catalog">
                            <p>Список блюд пуст</p>
                            <p>Попробуйте изменить условия фильтрации</p>
                        </EmptyBlock>
                    )}
                </div>
            </div>
            <div className="layout__bottom">
                <CartBtn />
            </div>
            <Modal
                visible={visibleFilters}
                onClose={onHideFilter}
            >
                <FilterProduct active={alias} setLoadingUI={setLoadingUI} onClose={onHideFilter}/>
            </Modal>
        </div>
    )
}

export default Subcategory
